import React from "react";

function HomeBanner() {
  return (
    <div className="banner-background banner-text text-white">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 col-lg-6 w-100">
            <div className="banner-heading">
              Empowering Innovation, Enhancing Care
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeBanner;
