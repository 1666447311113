import "./Contact.css";

function ContactBanner() {
  return (
    <div className="contact-banner-background banner-text text-white">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 col-lg-6 w-100">
            <div className="banner-heading">Contact Us</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactBanner;
