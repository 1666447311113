import React from "react";

function HealthcareSolutions() {
  return (
    <div className="section-container container">
      <h2 className="section-title">Our Healthcare Solutions</h2>
      <div className="content-text text-center">
        The healthcare industry relies on qualified and compassionate
        professionals to provide exceptional patient care. At Faith Lake
        Solutions, we specialize in healthcare staffing solutions, connecting
        healthcare facilities with top-tier talent. Whether you need temporary
        staff to cover seasonal demands or permanent placements for long-term
        support, we have the expertise to find the right fit for your
        organization.
      </div>
    </div>
  );
}

export default HealthcareSolutions;
