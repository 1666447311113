function SoftwareSolutions() {
  return (
    <div className="section-container">
      <h2 className="section-title">Our Innovative Solutions</h2>
      <div className="content-text text-center">
        In the fast-evolving digital landscape, having robust and scalable
        software is essential for businesses to stay competitive. At Faith Lake
        Solutions, we offer tailored software development services that align
        with your unique requirements. Our team of skilled developers leverages
        the latest technologies and industry best practices to create intuitive,
        secure, and user-friendly software applications.
      </div>
    </div>
  );
}

export default SoftwareSolutions;
