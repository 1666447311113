function HealthcareCards() {
  const data = [
    {
      title: "Skilled Nursing",
      description:
        "Providing a broad range of skilled nursing such as Nursing Aides and Nurse Practitioners providing inpatient and outpatient care",
    },
    {
      title: "Lab Services",
      description:
        "Providing services for testing specimens from the body that are used to diagnose and treat patients.",
    },
    {
      title: "Clinical Psychologist",
      description:
        "Providing comprehensive mental and behavioral care for individuals and families.",
    },
    {
      title: "Clinical Pharmacist",
      description:
        "Providing direct patient care that optimizes the use of medication to provide health, wellness & disease prevention.",
    },
    {
      title: "PEBLO Techs",
      description:
        "Providing information, assistance, and case status updates to the affected Service member throughout the Disability Evaluation System process",
    },
    {
      title: "Lab Technicians",
      description:
        "Providing services for recording data, protecting the accuracy and efficiency of scientific experiments, maintaining equipment and lab tools and keeping the lab organized",
    },
    {
      title: "Social Services",
      description:
        "Providing services to protect vulnerable children and support families in need of assistance.",
    },
    {
      title: "Patient Sitters",
      description:
        "Providing comfort for patients and looking out for their safety and ensuring they're satisfied with the level of care they're receiving",
    },
    {
      title: "Opthalmology",
      description:
        "Providing services for diagnosing and treating all eye diseases, including eye surgery, exams and prescriptions for eyeglasses and contact lenses",
    },
    {
      title: "Informatics",
      description:
        "Health informatics professionals use their knowledge of healthcare, information systems, databases and information technology security to gather, store, interpret and manage the massive amount of data generated when care is provided to patients",
    },
    {
      title: "Facility Management",
      description:
        "Providing services to oversee financial, technical and administrative operations within a health care organization. They must plan, organize, implement, evaluate and monitor all facility programs and departments",
    },
    {
      title: "Bio-medical Tech",
      description:
        "Providing maintenance to service medical equipment and work on diagnostic and treatment devices, such as CAT scanners and heart defibrillators",
    },
    {
      title: "Medical Records Technicians",
      description:
        "Ensuring the accurate management, organization, and transcription of patient’s medical histories, symptoms, diagnoses, and treatments using the customer's preferred medical records systems.",
    },
    {
      title: "Medical Logistic Technicians",
      description:
        "Providing warehouse management of medical supply inventory and coordination of delivery and maintenance.",
    },
    {
      title: "Optometry Technicians",
      description:
        "Assisting and working closely with optometrists, and perform the administrative duties and assist with vision acuity testing.",
    },
  ];
  const healthcareCards = data.map((item) => {
    return (
      <div className="col-lg-4 col-md-6 col-sm-12 hc-container">
        <div class="hc-card">
          <div class="card-body">
            <h5 class="hc-title">{item.title}</h5>
            <p class="hc-text">{item.description}</p>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className="container">
      <div className="row">{healthcareCards}</div>
    </div>
  );
}

export default HealthcareCards;
