import React from "react";
import { Link } from "react-router-dom";

function HomeNavButtons() {
  return (
    <div className="home-nav-buttons">
      <div className="row btn-block gx-0">
        <div className="col-lg-4 col-sm-12 btn-who">
          <div className="h2">
            <Link to="/software-development" className="linkStyle text-dark">
              Software Development
            </Link>
          </div>
        </div>
        <div className="col-lg-4 col-sm-12 btn-solutions">
          <div className="h2">
            <Link to="/healthcare-staffing" className="linkStyle text-white">
              Healthcare Staffing
            </Link>
          </div>
        </div>
        <div className="col-lg-4 col-sm-12 btn-contact">
          <div className="h2">
            <Link to="/contactus" className="linkStyle text-white">
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeNavButtons;
