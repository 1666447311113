// import './Software.css';

function SoftwareBanner() {
  return (
    <div className="sw-banner-background banner-text text-white">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 col-lg-6 w-100">
            <div className="banner-heading">Software Development</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SoftwareBanner;
