import { useEffect } from "react";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import ContactBanner from "../components/ContactComponents/ContactBanner";
import SendMsg from "../components/sendmsg/SendMsg";

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
}, []);
  return (
    <>
      <Navbar />
      <ContactBanner />\
      <SendMsg />
      <Footer />
    </>
  );
}

export default ContactUs;
