import React from "react";

function HomeMiddleBlock() {
  return (
    <div className="section-container">
      <div className="row my-5 justify-content-center">
        <div className="col-md-12 col-lg-4 col-xxl-4 d-flex justify-content-center">
          <img
            src="../assets/HandShake.png"
            alt="handshake"
            className="img-fluid home-img"
          />
        </div>
        <div className="col-md-12 col-lg-6 col-xxl-5 mt-3">
          <div className="content-text">
            Faith Lake Solutions is a leading provider of innovative software
            development and healthcare staffing solutions. With our expertise in
            both industries, we are uniquely positioned to cater to the diverse
            needs of businesses and healthcare facilities alike.
            <br />
            <br />
            Whether you require cutting-edge software solutions to drive your
            digital transformation or exceptional healthcare professionals to
            enhance patient care, we have the skills and resources to exceed
            your expectations.
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeMiddleBlock;
